import * as React from 'react';
import Layout, { cyanButtonStyle } from '../../components/layout';
import { Seo } from "../../components/seo";
import { BookOne, book1Description, keywords } from "../../components/bookinfo";
import MailSignup from '../../components/mailsignup';

export const Head = () => (
  <Seo
    title="The Secrets of Tercast (Source's Edge Book 1)"
    description={book1Description}
    pathname="/sourcesedge/thesecretsoftercast"
    keywords={keywords}
    type="book"
  />
)

const SotPage = () => {

  const [mailSignupOpen, setMailSignupOpen] = React.useState(false);

  function toggleMailSignup() {
    setMailSignupOpen(!mailSignupOpen);
  }

  return (
    <Layout pagePath="/sourcesedge/thesecretsoftercast">
      <MailSignup isOpen={mailSignupOpen} onClose={toggleMailSignup}/>
      <div className="container mx-auto px-4 text-center">
        <h1 className="hidden">The Secrets of Tercast (Source's Edge Book 1)</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <BookOne/>
        </div>
        <button className={cyanButtonStyle} onClick={toggleMailSignup}>Want to get updates on my work? Click here!</button>
      </div> 
    </Layout>
  )
};

export default SotPage;
